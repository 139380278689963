import React, { useEffect, useState } from "react";
import axios from 'axios';
import logo from './../evsforidiotsman.jpg';

const IdiotStatementsComponent = () => {
    const [datas, setDatas] = useState([])
    const [limit, setLimit] = useState(0) 

    useEffect(() => {
        axios({
            method: "GET",
            url: `https://cdn.contentful.com/spaces/irvlgkwqboy7/environments/master/entries?access_token=QhE_xJsTqEWr8PE4yi8B2rEH5yB1I4k4WdiK_oF9LVw`
        }).then((result) => setDatas(result.data.items)).catch((err) => console.log(err));
    }, [limit])

    for (let i in datas) { 
        if(datas[i].fields.statement !== 'CollectionOfStatements') {
            console.log("Idiotic Statement: " + datas[i].fields.statement);
            console.log("Reality Preview: " + datas[i].fields.realityPreview);
            console.log("Reality Content: " + datas[i].fields.realityContent);
        }
    }

    return (
        <div className="App">
            <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Coming Soon
            </p> 
            </header>
        </div>
    )
}

export default IdiotStatementsComponent;