import './App.css';
import IdiotStatementsComponent from './areas/idiotic-statements';

function App() {
  return (
    <IdiotStatementsComponent/>
  );
}

export default App;
